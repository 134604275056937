export const FormatNumberMixin = {
  methods: {
    formatNumber(num) {
      if (num >= 1e9) {
        return (num / 1e9).toFixed(1) + " b";
      }
      // Check if the num count is greater than or equal to 1 million
      else if (num >= 1e6) {
        return (num / 1e6).toFixed(1) + " m";
      }
      // Check if the num count is greater than or equal to 1 thousand
      else if (num >= 1e3) {
        return (num / 1e3).toFixed(1) + " k";
      }
      // For counts less than 1000
      else {
        return num.toString();
      }
    },
  },
};
