<template>
  <div class="donut-chart">
    <apexchart
      :width="width"
      :height="height"
      :options="options"
      :series="chartData"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  name: "Chart",
  props: {
    width: {
      type: Number,
      default: 180,
    },
    height: {
      type: Number,
      default: 210,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  computed: {
    options() {
      const colors = this.data.map((item) => item.color);
      return {
        chart: {
          type: "donut",
        },
        colors: colors,
        labels: this.data.map((job) => job.status),

        plotOptions: {
          pie: {
            donut: {
              size: "55%",
            },
          },
        },

        dataLabels: {
          enabled: true, // Disable data labels
        },

        legend: {
          show: false,
          // position: "bottom",
          // onItemClick: {
          //   toggleDataSeries: true,
          // },

          // customLegendItems: legendLabels,
        },
      };
    },
    chartData() {
      // console.log(
      //   this.data.map((item) => ({
      //     name: item.region,
      //     data: item.traffic,
      //   }))
      // );
      return this.data.map((item) => item.traffic);
    },
  },
};
</script>
<style lang="scss" scoped>
.apexcharts-legend {
  max-width: 145px;
  margin-bottom: 10px;
}
</style>
