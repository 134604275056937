import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { VueQueryPlugin } from "vue-query";
import { OhVueIcon, addIcons } from "oh-vue-icons";
import {
  RiDeleteBin5Line,
  IoCloseCircleOutline,
  FaInstagram,
  IoCaretDown,
  FaAngleDown,
  FaAngleUp,
  BiMeta,
  BiLinkedin,
  MdEmailRound,
  IoLocationSharp,
  HiSolidSearch,
  BiArrowRightShort,
  BiArrowRight,
  IoClose,
  LaExternalLinkSquareAltSolid,
  BiArrowRightCircleFill,
  LaFileDownloadSolid,
  BiTwitter,
  HiSolidLink,
  MdEmailOutlined,
  HiUser,
  IoCall,
  IoAttachOutline,
  PrShareAlt,
  BiBriefcase,
  BiBookmark,
  OiDotFill,
  GiHamburgerMenu,
  MdMorevert,
  HiSolidMenu,
  IoGridOutline,
  BiGrid,
  HiLocationMarker,
  HiRefresh,
  CoFilter,
  IoCloseOutline,
  FaSpinner,
} from "oh-vue-icons/icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

addIcons(
  RiDeleteBin5Line,
  IoCloseCircleOutline,
  FaInstagram,
  IoCaretDown,
  FaAngleDown,
  FaAngleUp,
  BiMeta,
  BiLinkedin,
  MdEmailRound,
  IoLocationSharp,
  HiSolidSearch,
  BiArrowRightShort,
  BiArrowRight,
  IoClose,
  BiBriefcase,
  LaExternalLinkSquareAltSolid,
  BiArrowRightCircleFill,
  LaFileDownloadSolid,
  BiTwitter,
  HiSolidLink,
  MdEmailOutlined,
  HiUser,
  IoCall,
  BiBookmark,
  PrShareAlt,
  IoAttachOutline,
  OiDotFill,
  GiHamburgerMenu,
  MdMorevert,
  HiSolidMenu,
  IoGridOutline,
  BiGrid,
  HiLocationMarker,
  HiRefresh,
  CoFilter,
  IoCloseOutline,
  FaSpinner,
);
const app = createApp(App);

app.component("v-icon", OhVueIcon);
app.component("fa", FontAwesomeIcon);
app.use(router);
app.use(store);
app.mount("#app");
app.use(VueQueryPlugin);
